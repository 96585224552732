import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b37557ac = () => interopDefault(import('../pages/absen/index.vue' /* webpackChunkName: "pages/absen/index" */))
const _8049d076 = () => interopDefault(import('../pages/bahan/index.vue' /* webpackChunkName: "pages/bahan/index" */))
const _ef798cba = () => interopDefault(import('../pages/bank/index.vue' /* webpackChunkName: "pages/bank/index" */))
const _7d951e3a = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _dce8e4a0 = () => interopDefault(import('../pages/draft/index.vue' /* webpackChunkName: "pages/draft/index" */))
const _59927886 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _73d07f78 = () => interopDefault(import('../pages/index_catalog.vue' /* webpackChunkName: "pages/index_catalog" */))
const _5465b248 = () => interopDefault(import('../pages/jadwal/index.vue' /* webpackChunkName: "pages/jadwal/index" */))
const _8d59dcd6 = () => interopDefault(import('../pages/kasir/index.vue' /* webpackChunkName: "pages/kasir/index" */))
const _5d501da0 = () => interopDefault(import('../pages/katalog/index.vue' /* webpackChunkName: "pages/katalog/index" */))
const _53d24de2 = () => interopDefault(import('../pages/konfigurasi/index.vue' /* webpackChunkName: "pages/konfigurasi/index" */))
const _ca932478 = () => interopDefault(import('../pages/kunjungan/index.vue' /* webpackChunkName: "pages/kunjungan/index" */))
const _52512bfa = () => interopDefault(import('../pages/laporan/index.vue' /* webpackChunkName: "pages/laporan/index" */))
const _66dac78d = () => interopDefault(import('../pages/layanan/index.vue' /* webpackChunkName: "pages/layanan/index" */))
const _b53f6970 = () => interopDefault(import('../pages/multi/index.vue' /* webpackChunkName: "pages/multi/index" */))
const _7e5d2c54 = () => interopDefault(import('../pages/note.vue' /* webpackChunkName: "pages/note" */))
const _0f798dac = () => interopDefault(import('../pages/panduan/index.vue' /* webpackChunkName: "pages/panduan/index" */))
const _82a3e256 = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _587ffb3f = () => interopDefault(import('../pages/pegawai/index.vue' /* webpackChunkName: "pages/pegawai/index" */))
const _246740a0 = () => interopDefault(import('../pages/pelanggan/index.vue' /* webpackChunkName: "pages/pelanggan/index" */))
const _b4125ef2 = () => interopDefault(import('../pages/pembayaran/index.vue' /* webpackChunkName: "pages/pembayaran/index" */))
const _2fc5827a = () => interopDefault(import('../pages/pengajuan/index.vue' /* webpackChunkName: "pages/pengajuan/index" */))
const _429bd94a = () => interopDefault(import('../pages/penjadwalan/index.vue' /* webpackChunkName: "pages/penjadwalan/index" */))
const _38faea88 = () => interopDefault(import('../pages/pinjam/index.vue' /* webpackChunkName: "pages/pinjam/index" */))
const _1c94cf54 = () => interopDefault(import('../pages/produk/index.vue' /* webpackChunkName: "pages/produk/index" */))
const _322bcea3 = () => interopDefault(import('../pages/profil/index.vue' /* webpackChunkName: "pages/profil/index" */))
const _60ab8e1a = () => interopDefault(import('../pages/riwayat/index.vue' /* webpackChunkName: "pages/riwayat/index" */))
const _6e96f545 = () => interopDefault(import('../pages/rutin/index.vue' /* webpackChunkName: "pages/rutin/index" */))
const _1d72e2f8 = () => interopDefault(import('../pages/saldo/index.vue' /* webpackChunkName: "pages/saldo/index" */))
const _76d51b51 = () => interopDefault(import('../pages/shift/index.vue' /* webpackChunkName: "pages/shift/index" */))
const _2363ed42 = () => interopDefault(import('../pages/titipan/index.vue' /* webpackChunkName: "pages/titipan/index" */))
const _9cf8c674 = () => interopDefault(import('../pages/toko/index.vue' /* webpackChunkName: "pages/toko/index" */))
const _25bd93f7 = () => interopDefault(import('../pages/transaksi/index.vue' /* webpackChunkName: "pages/transaksi/index" */))
const _00016b45 = () => interopDefault(import('../pages/auth/check.vue' /* webpackChunkName: "pages/auth/check" */))
const _0f7ca936 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _fc7545b4 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _6a410900 = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _c9c512ae = () => interopDefault(import('../pages/jadwal/desktop/index.vue' /* webpackChunkName: "pages/jadwal/desktop/index" */))
const _acc0f7a6 = () => interopDefault(import('../pages/jadwal/new/index.vue' /* webpackChunkName: "pages/jadwal/new/index" */))
const _656470dc = () => interopDefault(import('../pages/jadwal/tambah/index.vue' /* webpackChunkName: "pages/jadwal/tambah/index" */))
const _8d89d1d6 = () => interopDefault(import('../pages/kunjungan/new/index.vue' /* webpackChunkName: "pages/kunjungan/new/index" */))
const _5c5960ca = () => interopDefault(import('../pages/penjadwalan/new/index.vue' /* webpackChunkName: "pages/penjadwalan/new/index" */))
const _42e9f2fa = () => interopDefault(import('../pages/jadwal/new/desktop/index.vue' /* webpackChunkName: "pages/jadwal/new/desktop/index" */))
const _14c7fd21 = () => interopDefault(import('../pages/jadwal/new/tambah/index.vue' /* webpackChunkName: "pages/jadwal/new/tambah/index" */))
const _44d6bdb4 = () => interopDefault(import('../pages/jadwal/new/desktop/tambah/index.vue' /* webpackChunkName: "pages/jadwal/new/desktop/tambah/index" */))
const _7c9a48c4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _fbc6ddb2 = () => interopDefault(import('../pages/jadwal/new/desktop/ubah/_id.vue' /* webpackChunkName: "pages/jadwal/new/desktop/ubah/_id" */))
const _356ee1d8 = () => interopDefault(import('../pages/jadwal/new/ubah/_id.vue' /* webpackChunkName: "pages/jadwal/new/ubah/_id" */))
const _100d3a40 = () => interopDefault(import('../pages/transaksi/payment/multi/_id.vue' /* webpackChunkName: "pages/transaksi/payment/multi/_id" */))
const _3d66a81f = () => interopDefault(import('../pages/transaksi/payment/pembayaran/_id.vue' /* webpackChunkName: "pages/transaksi/payment/pembayaran/_id" */))
const _81fa5772 = () => interopDefault(import('../pages/auth/reset/_slug.vue' /* webpackChunkName: "pages/auth/reset/_slug" */))
const _491bd792 = () => interopDefault(import('../pages/invoice/print/_slug.vue' /* webpackChunkName: "pages/invoice/print/_slug" */))
const _10218bf6 = () => interopDefault(import('../pages/jadwal/ubah/_id.vue' /* webpackChunkName: "pages/jadwal/ubah/_id" */))
const _1dd71416 = () => interopDefault(import('../pages/transaksi/payment/_id.vue' /* webpackChunkName: "pages/transaksi/payment/_id" */))
const _0234b064 = () => interopDefault(import('../pages/feedback/_slug.vue' /* webpackChunkName: "pages/feedback/_slug" */))
const _5ee779f4 = () => interopDefault(import('../pages/invoice/_slug.vue' /* webpackChunkName: "pages/invoice/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/absen",
    component: _b37557ac,
    name: "absen"
  }, {
    path: "/bahan",
    component: _8049d076,
    name: "bahan"
  }, {
    path: "/bank",
    component: _ef798cba,
    name: "bank"
  }, {
    path: "/dashboard",
    component: _7d951e3a,
    name: "dashboard"
  }, {
    path: "/draft",
    component: _dce8e4a0,
    name: "draft"
  }, {
    path: "/home",
    component: _59927886,
    name: "home"
  }, {
    path: "/index_catalog",
    component: _73d07f78,
    name: "index_catalog"
  }, {
    path: "/jadwal",
    component: _5465b248,
    name: "jadwal"
  }, {
    path: "/kasir",
    component: _8d59dcd6,
    name: "kasir"
  }, {
    path: "/katalog",
    component: _5d501da0,
    name: "katalog"
  }, {
    path: "/konfigurasi",
    component: _53d24de2,
    name: "konfigurasi"
  }, {
    path: "/kunjungan",
    component: _ca932478,
    name: "kunjungan"
  }, {
    path: "/laporan",
    component: _52512bfa,
    name: "laporan"
  }, {
    path: "/layanan",
    component: _66dac78d,
    name: "layanan"
  }, {
    path: "/multi",
    component: _b53f6970,
    name: "multi"
  }, {
    path: "/note",
    component: _7e5d2c54,
    name: "note"
  }, {
    path: "/panduan",
    component: _0f798dac,
    name: "panduan"
  }, {
    path: "/payment",
    component: _82a3e256,
    name: "payment"
  }, {
    path: "/pegawai",
    component: _587ffb3f,
    name: "pegawai"
  }, {
    path: "/pelanggan",
    component: _246740a0,
    name: "pelanggan"
  }, {
    path: "/pembayaran",
    component: _b4125ef2,
    name: "pembayaran"
  }, {
    path: "/pengajuan",
    component: _2fc5827a,
    name: "pengajuan"
  }, {
    path: "/penjadwalan",
    component: _429bd94a,
    name: "penjadwalan"
  }, {
    path: "/pinjam",
    component: _38faea88,
    name: "pinjam"
  }, {
    path: "/produk",
    component: _1c94cf54,
    name: "produk"
  }, {
    path: "/profil",
    component: _322bcea3,
    name: "profil"
  }, {
    path: "/riwayat",
    component: _60ab8e1a,
    name: "riwayat"
  }, {
    path: "/rutin",
    component: _6e96f545,
    name: "rutin"
  }, {
    path: "/saldo",
    component: _1d72e2f8,
    name: "saldo"
  }, {
    path: "/shift",
    component: _76d51b51,
    name: "shift"
  }, {
    path: "/titipan",
    component: _2363ed42,
    name: "titipan"
  }, {
    path: "/toko",
    component: _9cf8c674,
    name: "toko"
  }, {
    path: "/transaksi",
    component: _25bd93f7,
    name: "transaksi"
  }, {
    path: "/auth/check",
    component: _00016b45,
    name: "auth-check"
  }, {
    path: "/auth/forgot",
    component: _0f7ca936,
    name: "auth-forgot"
  }, {
    path: "/auth/login",
    component: _fc7545b4,
    name: "auth-login"
  }, {
    path: "/auth/success",
    component: _6a410900,
    name: "auth-success"
  }, {
    path: "/jadwal/desktop",
    component: _c9c512ae,
    name: "jadwal-desktop"
  }, {
    path: "/jadwal/new",
    component: _acc0f7a6,
    name: "jadwal-new"
  }, {
    path: "/jadwal/tambah",
    component: _656470dc,
    name: "jadwal-tambah"
  }, {
    path: "/kunjungan/new",
    component: _8d89d1d6,
    name: "kunjungan-new"
  }, {
    path: "/penjadwalan/new",
    component: _5c5960ca,
    name: "penjadwalan-new"
  }, {
    path: "/jadwal/new/desktop",
    component: _42e9f2fa,
    name: "jadwal-new-desktop"
  }, {
    path: "/jadwal/new/tambah",
    component: _14c7fd21,
    name: "jadwal-new-tambah"
  }, {
    path: "/jadwal/new/desktop/tambah",
    component: _44d6bdb4,
    name: "jadwal-new-desktop-tambah"
  }, {
    path: "/",
    component: _7c9a48c4,
    name: "index"
  }, {
    path: "/jadwal/new/desktop/ubah/:id?",
    component: _fbc6ddb2,
    name: "jadwal-new-desktop-ubah-id"
  }, {
    path: "/jadwal/new/ubah/:id?",
    component: _356ee1d8,
    name: "jadwal-new-ubah-id"
  }, {
    path: "/transaksi/payment/multi/:id?",
    component: _100d3a40,
    name: "transaksi-payment-multi-id"
  }, {
    path: "/transaksi/payment/pembayaran/:id?",
    component: _3d66a81f,
    name: "transaksi-payment-pembayaran-id"
  }, {
    path: "/auth/reset/:slug?",
    component: _81fa5772,
    name: "auth-reset-slug"
  }, {
    path: "/invoice/print/:slug?",
    component: _491bd792,
    name: "invoice-print-slug"
  }, {
    path: "/jadwal/ubah/:id?",
    component: _10218bf6,
    name: "jadwal-ubah-id"
  }, {
    path: "/transaksi/payment/:id?",
    component: _1dd71416,
    name: "transaksi-payment-id"
  }, {
    path: "/feedback/:slug?",
    component: _0234b064,
    name: "feedback-slug"
  }, {
    path: "/invoice/:slug?",
    component: _5ee779f4,
    name: "invoice-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
